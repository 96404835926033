export interface ISagaOptions {
  config: {
    action: string | string[],
    method: CallableFunction,
  },
  func: CallableFunction,
}

export default function connect(list: Array<ISagaOptions>): () => Generator<void> {
  return function* generator() {
    for (let key = 0; key < list.length; key += 1) {
      const item: ISagaOptions = list[key];
      yield item.config.method(item.config.action, item.func);
    }
  };
}
